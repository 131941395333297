<template>
  <b-modal
    ref="calls-and-sms-tracking"
    :title="title"
    modal-class="modal-primary"
    title-tag="h3"
    hide-footer
    @hidden="closeModal"
    size="xmd"
  >
    <div>
      <b-table
        ref="refTrackingTable"
        class="position-relative font-small-3"
        primary-key="id"
        empty-text="No matching records found"
        select-mode="multi"
        responsive="sm"
        sticky-header="68vh"
        small
        show-empty
        :fields="fields"
        :items="callsAndSmsData"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(commentary)="data">
          <change-sms :SmsData="data.item.commentary" />
        </template>

        <!-- ocultar columna -->

        <template #cell(image)="data">
          <!-- feather-icon  -->
          <div v-if="data.item.url_image">
            <a :href="data.item.url_image" target="_blank">
              <feather-icon
                icon="ImageIcon"
                class="font-medium-5"
                :stroke-width="2"
              />
            </a>
          </div>
        </template>
        <template #cell(created_at)="data">
          <div>
            <span>{{ data.item.created_at | myGlobalDay }}</span>
          </div>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<script>
import fields from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/data/fields.calls-and-sms-tracking";
import CallsAndSmsService from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/services/index";
import { mapGetters } from "vuex";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms";
export default {
  name: "CallsAndSmsTracking",
  props: {
    clientData: {
      type: Object,
      default: null,
    },
    typeTracking: {
      type: Number,
      default: null,
    },
  },
  components: {
    ChangeSms,
  },
  data() {
    return {
      fields,
      callsAndSmsData: null,
      title: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    if(this.fields[3]){
      // eliminar
      this.fields.splice(3, 1);
  }
    this.setTitle();
    await this.getCallsAndSms();
  },
  mounted() {
    this.toggleModal("calls-and-sms-tracking");
  },
  methods: {
    setTitle() {
      switch (this.typeTracking) {
        case 1:
          this.title = "CALLS TRACKING";
          break;
        case 2:
          this.title = "SMS TRACKING";
          break;
        case 3:
          this.title = "COMMENTARIES TRACKING";
          // agregar
          //           {
          //   key: 'image',
          //   label: 'Image',
          //   thClass: 'text-center',
          //   tdClass: 'text-center',
          // }
          this.fields.push({
            key: "image",
            label: "Image",
            thClass: "text-center",
            tdClass: "text-center",
          });

          break;
        case 4:
          this.title = "NOTIFICATIONS TRACKING";
          break;
        default:
          this.title = "TRACKING";
          break;
      }
    },
    async getCallsAndSms() {
      const params = {
        client_account_id: this.clientData.client_account_id,
        type_tracking: this.typeTracking,
        created_by: this.isAgent ? this.currentUser.user_id : null,
        settlement_date: this.clientData.settlement_date
          ? this.clientData.settlement_date
          : null,
      };
      const data = await CallsAndSmsService.getCallsSmsCommentaryTracking(
        params
      );
      if (data.status === 200) {
        this.callsAndSmsData = data.data;
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
</style>
