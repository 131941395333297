import { amgApi } from '@/service/axios'

class CallsAndSmsService {
  async getCeDigitalCallsAndSms(params) {
    const data = await amgApi.post('credit-experts-digital/financial/get-ce-digital-calls-and-sms', params)
    return data
  }

  async addCeDigitalCallsAndSms(params) {
    const response = await amgApi.post('credit-experts-digital/financial/add-ce-digital-calls-and-sms', params)
    return response
  }

  async getCeDigitalCallsAndSmsTracking(params) {
    const data = await amgApi.post('credit-experts-digital/financial/get-ce-digital-calls-and-sms-tracking', params)
    return data
  }

  async getCallsSmsCommentaryTracking(params) {
    const data = await amgApi.post('credit-experts-digital/financial/get-calls-sms-commentary-tracking', params)
    return data
  }

  async reschedulePendingPayment(params) {
    const data = await amgApi.post('credit-experts-digital/financial/reschedule-pending-payment', params)
    return data
  }

  async getAgentScheduleList(params) {
    const data = await amgApi.post('credit-experts-digital/financial/get-agent-schedule-list', params)
    return data
  }
  async getAgentSchedulePendingList(params) {
    const data = await amgApi.post('credit-experts-digital/financial/get-agent-schedule-pending-list', params)
    return data
  }

  async getActiveFinancialAgents() {
    const data = await amgApi.get('credit-experts-digital/financial/get-all-financial-agents')
    return data
  }
  async getRescheduleById(params) {
    const data = await amgApi.post('credit-experts-digital/financial/get-reschedule-by-id', params)
    return data
  }
  async deleteRescheduleById(params) {
    const data = await amgApi.post('credit-experts-digital/financial/delete-reschedule-by-id', params)
    return data
  }
}

export default new CallsAndSmsService()
