export default [
  {
    key: 'created_at',
    label: 'Date',
  },
  {
    key: 'agent_name',
    label: 'Agent',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'commentary',
    label: 'Commentary',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
]
