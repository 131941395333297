export default [
    {
      type: 'datepicker',
      margin: true,
      showLabel: true,
      label: 'From',
      placeholder: 'Date',
      class: 'font-small-3',
      model: null,
      locale: 'en',
      dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
      cols: 6,
    },
    {
      type: 'datepicker',
      margin: true,
      showLabel: true,
      label: 'To',
      placeholder: 'Date',
      class: 'font-small-3',
      model: null,
      locale: 'en',
      dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
      cols: 6,
    },
    {
      type: 'select',
      margin: true,
      showLabel: true,
      label: 'Client Type',
      model: null,
      options: [],
      reduce: 'id',
      selectText: 'name',
      cols: 12,
    },
    {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Source',
        model: null,
        options: [],
        reduce: 'id',
        selectText: 'name',
        cols: 12,
      },
      {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Transaction Type',
        model: null,
        options: [
          {
              "id": 1,
              "name": "Manual"
          },
          {
              "id": 2,
              "name": "Others"
          },
          {
              "id": 3,
              "name": "Zero Payment"
          }
      ],
        reduce: 'id',
        selectText: 'name',
        cols: 12,
      },
      {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Agent',
        model: null,
        options: [],
        reduce: 'id',
        selectText: 'fullName',
        cols: 12,
      },
  ]
  