<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      :change-columns-by-client="true"
      @reload="updateTable"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refChargesMadeTable"
          small
          no-provider-filtering
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(account)="data">
            <router-link
              :class="[textLink]"
              :to="{
                name: 'financial-clients-account',
                params: {
                  idClient: data.item.client_account_id,
                },
              }"
              target="_blank"
              >{{ data.item.client_name }}
            </router-link>
            <div>
              <status-account :account="data.item" />
            </div>
            <span> {{ data.item.account }}</span>
            <br />
            <span>
              {{ data.item.mobile ? data.item.mobile : "(###) ###-####" }}</span
            >
          </template>
          <template #cell(amount)="data"> $ {{ data.item.amount }} </template>
          <template #cell(client_type_name)="data">
            <b-badge
              :variant="data.item.client_type == 1 ? 'primary' : 'success'"
            >
              {{ data.item.client_type_name }}
            </b-badge>
          </template>
          <template #cell(type_transaction)="data">
            {{
              data.item.type_transaction == 2 &&
              data.item.method_transaction_id == 5 &&
              data.item.modality_transaction_id == 1
                ? "Others"
                : data.item.type_transaction_name
            }}
          </template>
          <template #cell(settlement_date)="data">
            <div style="width: 180px">
              {{ data.item.settlement_user }} <br />
              <div class="d-flex">
                <p class="p-0 m-0">
                  {{ data.item.settlement_date | myGlobalDay }}
                </p>
                <div v-if="data.item.update_payment_date == 0">
                  <!-- <feather-icon
                    v-if="
                      !data.item.editSettlementDate && status == 1
                    "
                    class="ml-1 cursor-pointer text-success"
                    icon="Edit3Icon"
                    size="20"
                    @click="editDate(data.item)"
                  /> v-else en el siguiente feather icon-->

                  <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="AlignRightIcon"
                    size="20"
                    @click="openTracking(data.item)"
                  />
                </div>
                <div v-else>
                  <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="AlignRightIcon"
                    size="20"
                    @click="openTracking(data.item)"
                  />
                </div>
              </div>
              <div v-if="data.item.editSettlementDate">
                <validation-observer :ref="`reschedule${data.item.account}`">
                  <validation-provider
                    v-slot="{ errors }"
                    name="date"
                    rules="required"
                  >
                    <kendo-datepicker
                      :key="data.index"
                      id="contactDate"
                      v-model="data.item.newSettlementDate"
                      v-mask="'##/##/####'"
                      :min="minDate"
                      :format="'MM/dd/yyyy'"
                      class="leads-datepicker rounded bg-transparent k-picker-custom picker-select-date"
                      :class="{
                        'border-danger': errors[0],
                      }"
                    />

                    <feather-icon
                      class="ml-1 cursor-pointer text-success"
                      icon="CheckIcon"
                      size="20"
                      @click="saveDate(data.item)"
                    />
                    <feather-icon
                      @click="hiddenDate(data.item)"
                      class="ml-1 cursor-pointer text-danger"
                      icon="XIcon"
                      size="20"
                    />
                  </validation-provider>
                </validation-observer>
              </div>
            </div>
          </template>

          <template #cell(count_call)="data">
            <b-badge
              class="cursor-pointer"
              variant="primary"
              @click="openCallsAndSmsTrackingModal(data.item, 1)"
              >{{
                data.item.count_call === null ? 0 : data.item.count_call
              }}</b-badge
            >
          </template>

          <template #cell(count_sms)="data">
            <b-badge
              class="cursor-pointer"
              variant="primary"
              @click="openCallsAndSmsTrackingModal(data.item, 2)"
              >{{
                data.item.count_sms === null ? 0 : data.item.count_sms
              }}</b-badge
            >
          </template>

          <template #cell(commentary)="data">
            <feather-icon
              class="mr-1 cursor-pointer"
              icon="AlignRightIcon"
              size="20"
              @click="openCallsAndSmsTrackingModal(data.item, 3)"
            />
          </template>

          <template #custom-foot>
            <b-tr>
              <b-td></b-td>
              <b-td></b-td>
              <b-td class="text-left font-weight-bolder py-px"> Subtotal </b-td>
              <b-td class="text-left py-px">
                <b-badge class="w-100">
                  $ {{ subTotalAmount | currency }}
                  <!-- {{ "$ " + subTotalAmount }} -->
                </b-badge>
              </b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
            </b-tr>
            <b-tr>
              <b-td></b-td>
              <b-td></b-td>
              <b-td class="text-left font-weight-bolder py-px"> Total </b-td>
              <b-td class="text-left py-px">
                <b-badge class="w-100">
                  $ {{ totalAmount | currency }}
                </b-badge>
              </b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
            </b-tr>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <calls-and-sms-tracking
      v-if="callsAndSmsTrackingModal"
      :client-data="clientData"
      :type-tracking="typeTracking"
      @closeModal="closeModal"
    />
    <tracking-settlement-date
      v-if="stateTrackingSettlementDate"
      :client-data="clientData"
      @hideModal="stateTrackingSettlementDate = false"
    />
  </div>
</template>

<script>
import ChargesMadeService from "../charges-mage.service";
import fields from "../charges-made.fields";
import filters from "../charges-made.filters";
import { mapGetters } from "vuex";
import CallsAndSmsTracking from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/CallsAndSmsTrackingModal.vue";
import TrackingSettlementDate from "./modal/TrackingSettlementDate.vue";
import moment from "moment";
import ViewClientDetails from "@/views/ce-digital/sub-modules/financial/components/ViewClientDetails.vue";
import CallsAndSmsService from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/services";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  components: {
    CallsAndSmsTracking,
    TrackingSettlementDate,
    ViewClientDetails,
    StatusAccount,
  },
  props: {
    status: {
      type: Number,
      default: 1,
    },
  },
  async mounted() {
    await Promise.all([
      this.getAllClientTypes(),
      this.getPaymentTypesFinancial(),
      // this.getTypesTransactions(),
      this.dateValidate(),
      this.getActiveFinancialAgents(),
    ]);
  },
  data() {
    return {
      paginate: {
        currentPage: 1,
        perPage: 10,
      },

      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      totalRows: 0,
      payments: [],
      fields,
      filters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Account...",
        model: "",
      },
      isBusy: false,
      sortBy: "settlement_date",
      sortDesc: true,
      images: [],
      indexImage: null,
      selectedOtherPaymentId: null,
      openTrackingOtherPayment: false,
      callsAndSmsTrackingModal: false,
      clientData: {},
      typeTracking: null,
      stateTrackingSettlementDate: false,
      minDate: new Date(1000, 1, 1),
      stateEdit: true,
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    totalAmount() {
      if (this.payments.length > 0) {
        return this.payments[0].totalF;
      } else {
        return 0;
      }
    },
    subTotalAmount() {
      if (this.payments.length > 0) {
        // return sum amount of payments
        return this.payments.reduce((a, b) => a + (Number(b.amount) || 0), 0);
      } else {
        return 0;
      }
    },
  },
  methods: {
    dateValidate() {
      let currentDate = new Date();
      let maxDate = new Date(
        `${new Date().getFullYear()}, ${new Date().getMonth() + 1}, 15 `
      );
      const fromDay = moment(currentDate.toDateString()).format("DD");
      const toDay = moment(maxDate.toDateString()).format("DD");
      if (fromDay > toDay) {
        this.stateEdit = false;
      }
    },
    openTracking(item) {
      this.clientData = item;
      this.stateTrackingSettlementDate = true;
    },
    editDate(item) {
      this.$set(item, "editSettlementDate", true);
    },
    hiddenDate(item) {
      this.$set(item, "editSettlementDate", false);
      this.$set(item, "newSettlementDate", null);
    },
    async getActiveFinancialAgents() {
      try {
        const { data } = await CallsAndSmsService.getActiveFinancialAgents();
        this.filters[this.filters.length - 1].options = data;
      } catch (error) {
        console.log(error);
      }
    },
    async saveDate(item) {
      const validate = await this.$refs[`reschedule${item.account}`].validate();
      if (validate) {
        try {
          let result = await this.showConfirmSwal();
          if (result.isConfirmed) {
            let params = {
              user_id: this.currentUser.user_id,
              oldSettlementDate: item.settlement_date,
              newSettlementDate: item.newSettlementDate,
              transaction_id: item.transaction_id,
            };
            const { status } =
              await ChargesMadeService.updateSettlementDateTransaction(params);

            if (status == 200) {
              this.$set(item, "editSettlementDate", false);
              this.updateTable();
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Date updated successfully"
              );
            }
          }
        } catch (error) {
          console.log(error);
          `  `;
        }
      }
    },

    async getAllClientTypes() {
      try {
        const response = await ChargesMadeService.getAllClientTypes();
        this.filters[2].options = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getPaymentTypesFinancial() {
      try {
        const response = await ChargesMadeService.getPaymentTypesFinancial();
        this.filters[3].options = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    // async getTypesTransactions() {
    //   try {
    //     const response = await ChargesMadeService.getTypesTransactions();
    //     this.filters[4].options = response.data;
    //     this.filters[4].options = this.filters[4].options.filter(
    //       (item) => item.id !== 1
    //     );
    //     const index = this.filters[4].options.findIndex(
    //       (item) => item.id === 34
    //     );
    //     this.filters[4].options[index].name = "Others";
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async myProvider(ctx) {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          orderBy: ctx.sortBy === "" ? "settlement_date" : ctx.sortBy,
          order: ctx.sortDesc ? "desc" : "asc",
          perPage: ctx.perPage,
          page: ctx.currentPage,
          from: this.filters[0].model,
          to: this.filters[1].model,
          status: this.$route.meta.tab,
          status_transaction:
            this.$route.meta.tab != 1 ? 3 : this.$route.meta.tab,
          client_type: this.filters[2].model,
          payment_type: this.filters[3].model,
          type_transaction: this.filters[4].model,
          agent_id: this.filters[5].model,
          tab: this.$route.meta.tab,
        };
        const { data } = await ChargesMadeService.getCeChargesMade(params);
        const {
          data: paymentsT,
          total,
          from,
          current_page,
          per_page,
          last_page,
          to,
        } = data;
        this.payments = paymentsT;
        this.startPage = from;
        this.paginate.currentPage = current_page;
        this.paginate.perPage = per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = last_page;
        this.totalRows = total;
        this.toPage = to;
        this.payments.map((item) => {
          this.$set(item, "editSettlementDate", false);
          this.$set(item, "newSettlementDate", null);

          let oldMonth = moment(new Date()).month();
          let newMonth = moment(item.settlement_date).month();

          if (newMonth - oldMonth == 0) {
            this.$set(item, "stateDate", true);
          } else {
            this.$set(item, "stateDate", false);
          }
        });
        return this.payments || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    updateTable() {
      this.$refs.refChargesMadeTable.refresh();
    },
    openCallsAndSmsTrackingModal(item, type) {
      this.callsAndSmsTrackingModal = true;
      this.clientData = item;
      this.typeTracking = type;
    },
    closeModal() {
      this.callsAndSmsTrackingModal = false;
    },
  },
};
</script>