export default [
    {
      key: "account",
      label: "Client",
      sortable: true,
      visible: true,
    },
    {
      key: "mobile",
      label: "Mobile",
      sortable: true,
      visible: true,
    },
    {
      key: "client_type_name",
      label: "Client Type",
      sortable: true,
      visible: true,
    },
    {
      key: "amount",
      label: "Amount",
      sortable: true,
      visible: true,
    },
    {
      key: "payment_type_name",
      label: "From",
      sortable: true,
      visible: true,
    },
    {
      key: "transaction_id",
      label: "Transaction ID",
      sortable: true,
      visible: true,
    },
    {
      key: "settlement_date",
      label: "Settlement Date",
      sortable: true,
      visible: true,
    },
    {
        key: 'count_call',
        label: 'Calls',
        visible: true,
    },
    {
        key: 'count_sms',
        label: 'Sms',
        visible: true,
    },
    {
        key: 'commentary',
        label: 'Commentaries',
        tdClass: 'w-50 text-center',
        thClass: 'text-center',
        visible: true,
    }
  ]
  